@font-face {
	font-family: 'Futura';
	src: url('./Futura.woff2') format('woff2'),
		url('./Futura.woff') format('woff'),
		url('./Futura.ttf') format('truetype'),
		url('./Futura.eot') format('embedded-opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'FuturaPT';
	src: url('./FuturaPTLight.otf') format("otf");
	font-weight: normal;
	font-style: normal;
}

/* WoodWood */

@font-face {
	font-family: 'WWBeta8R';
	src: url('./WWBeta8-Black.otf') format('otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'WWBeta8R';
	src: url('./WWBeta8-Bold.otf') format('otf');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'WWBeta8R';
	src: url('./WWBeta8-Light.otf') format('otf');
	font-weight: inherit;
	font-style: normal;
}

@font-face {
	font-family: 'WWBeta8R';
	src: url('./WWBeta8-Medium.otf') format('otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'WWBeta8R';
	src: url('./WWBeta8-Regular.otf') format('otf');
	font-weight: inherit;
	font-style: normal;
}

@font-face {
	font-family: 'WWBeta8R';
	src: url('./WWBeta8-Thin.otf') format('otf');
	font-weight: initial;
	font-style: normal;
}

@font-face {
	font-family: 'IBMPlex';
	src: url('./IBMPlexSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

/* BY MALINA */

@font-face {
	font-family: 'Playfair';
	src: url('./PlayfairDisplay-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'PlayfairB';
	src: url('./PlayfairDisplay-Medium.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'PlayfairL';
	src: url('./PlayfairDisplay-SemiBold.ttf') format('truetype');
	font-weight: inherit;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('./AvenirLTStd-Black.otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'AvenirBook';
	src: url('./AvenirLTStd-Book.otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'AvenirRoman';
	src: url('./AvenirLTStd-Roman.otf');
	font-weight: inherit;
	font-style: normal;
}

/* HENRY LLOYD */

@font-face {
	font-family: 'Gotham';
	src: url('./GothamMedium.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

/* CHIMI */

@font-face {
	font-family: 'AmericaExpandedRegular';
	src: url('./GT-America-Expanded-Regular.otf') format('otf');
	font-style: normal;
	font-weight: normal;
  }
  
  @font-face {
	font-family: 'AmericaExpandedMedium';
	src: url('./GT-America-Extended-Medium.otf') format('otf');
	font-style: normal;
	font-weight: normal;
  }
  
  @font-face {
	font-family: 'AmericaExpandedThin';
	src: url('./GT-America-Extended-Thin.otf') format('otf');
	font-style: normal;
	font-weight: normal;
  }

/* MUSTO */

@font-face {
	font-family: 'Open Sans';
	src: url('./opensans-regular-webfont.woff2') format('woff2'),
		url('./opensans-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
  }
  
  @font-face {
	font-family: 'Open Sans Light';
	src: url('./opensans-light-webfont.woff2') format('woff2'),
		url('./opensans-light-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
  }
  
  @font-face {
	font-family: 'Open Sans Semibold';
	src: url('./opensans-semibold-webfont.woff2') format('woff2'),
		url('./opensans-semibold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
  }